<template>
  <common>
    <div class="gallery-layout">
      <slot></slot>
    </div>
  </common>
</template>

<script lang="ts">
import Common from '~/layouts/common.vue'

export default {
  name: 'gallery',
  components: { Common },
}
</script>

<style scoped lang="scss"></style>
